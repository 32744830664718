import Vue from "vue";
import VueRouter, { RouteConfig } from "vue-router";
import store from "../store";

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
    // {
    //     path: "/",
    //     name: "Dashboard",
    //     component: () =>
    //         import(
    //             /* webpackChunkName: "dashboard" */ "../views/Dashboard.vue"
    //         ),
    //     meta: { title: "metaverse-office", requiresAuth: true },
    //     props: true,
    // },
    {
        path: "/",
        name: "Landing",
        component: () =>
            import(/* webpackChunkName: "landing" */ "../views/Dashboard.vue"),
        meta: { title: "metaverse-office", requiresAuth: false },
    },
    {
        path: "/oauth",
        name: "oauth",
        component: () =>
            import(
                /* webpackChunkName: "OAuthLogin" */ "../components/OAuthLogin.vue"
            ),
        meta: { title: "metaverse-office" },
        props: route => ({
            code: route.query.code,
            scope: route.query.scope,
            state: route.query.state,
        }),
    },
    {
        path: "/registration_complete",
        name: "RegistrationComplete",
        component: () =>
            import(
                /* webpackChunkName: "registrationcomplete" */ "../views/RegistrationComplete.vue"
            ),
        meta: { title: "metaverse-office", requiresAuth: false },
    },
    {
        path: "/passwordReset",
        props: route => ({
            token: route.query.token,
        }),
        name: "passwordReset",
        component: () =>
            import(
                /* webpackChunkName: "passwordReset" */ "../views/PasswordReset.vue"
            ),
        meta: { title: "metaverse-office", requiresAuth: false },
    },
    {
        path: "/verify",
        props: route => ({
            token: route.query.token,
        }),
        name: "VerificationComplete",
        component: () =>
            import(
                /* webpackChunkName: "verificationcomplete" */ "../views/VerificationComplete.vue"
            ),
        meta: { title: "metaverse-office", requiresAuth: false },
    },
    {
        path: "/dashboard",
        name: "Dashboard",
        component: () =>
            import(
                /* webpackChunkName: "dashboard" */ "../views/Dashboard.vue"
            ),
        meta: { title: "Dashboard", requiresAuth: true },
    },
    {
        path: "/office-manager",
        name: "OfficeManager",
        component: () =>
            import(
                /* webpackChunkName: "officeManager" */ "../views/OfficeManager.vue"
            ),
        meta: { title: "Office Manager", requiresAuth: true },
    },
    {
        path: "/secure-entry",
        name: "SecureEntry",
        component: () =>
            import(
                /* webpackChunkName: "secureEntry" */ "../views/SecureEntry.vue"
            ),
        meta: { title: "Secure Entry", requiresAuth: false },
        props: route => ({
            playUri: route.query.playUri,
            officeId: route.query.officeId,
        }),
    },
    {
        path: "/user-manager",
        name: "UserManager",
        component: () =>
            import(
                /* webpackChunkName: "userManager" */ "../views/UserManager.vue"
            ),
        meta: { title: "User Manager", requiresAuth: true },
    },
    {
        path: "/account-provisioning",
        name: "Account Provisioning",
        props: route => ({
            token: route.query.token,
        }),
        component: () =>
            import(
                /* webpackChunkName: "officeManager" */ "../views/AccountProvisioning.vue"
            ),
        meta: { title: "Account Provisioning", requiresAuth: false },
    },
    {
        path: "/tutorial",
        name: "Tutorial",
        component: () =>
            import(/* webpackChunkName: "tutorial" */ "../views/Tutorial.vue"),
        meta: { title: "Tutorial", requiresAuth: false },
    },
];

const router = new VueRouter({
    mode: "history",
    base: process.env.BASE_URL,
    routes,
    scrollBehavior: to => {
        if (to.hash) {
            return { selector: to.hash };
        } else {
            return { x: 0, y: 0 };
        }
    },
});
const DEFAULT_TITLE = "metaverse-office";
router.afterEach(to => {
    Vue.nextTick(() => {
        document.title = to.meta?.title || DEFAULT_TITLE;
    });
});

router.beforeEach(async (to, from, next) => {
    // routeguard
    const isUserLoggedIn = store.getters.isAuthenticated;
    if (to.matched.some(record => record.meta.requiresAuth)) {
        // if token is expired and route protected, push back to login

        if (!isUserLoggedIn) {
            // User is not logged in but trying to open page which requires
            // TODO this redirection gets lost in oauth
            next({
                path: "/oauth",
                query: { redirect: to.fullPath },
            });
            store.commit("showSnackbar", {
                text: "Session abgelaufen",
                color: "error",
            });
        } else {
            // User is logged in and trying to open page which requires auth
            next();
        }
    } else if (to.name === "Register" && isUserLoggedIn) {
        // User logged in but still trying to open login page
        next({ name: "OfficeManager" });
    } else {
        // User is trying to open page which doesn't require auth
        next();
    }
});
export default router;
