import { User } from "@/interfaces/user";
import axios from "axios";
import request from "axios";

export class UserService {
    public static async getMe(token: string): Promise<User> {
        const uri = `/api/users/me`;

        const config = {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        };
        try {
            const response = await axios.get(uri, config);
            return response.data;
        } catch (error: unknown) {
            if (request.isAxiosError(error) && error.response) {
                return error.response.data;
            } else {
                throw Error("Unknown Error");
            }
        }
    }

    public static async getAll(token: string): Promise<User[]> {
        const uri = `/api/users/data/all`;

        const config = {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        };
        try {
            const response = await axios.get(uri, config);
            return response.data;
        } catch (error: unknown) {
            if (request.isAxiosError(error) && error.response) {
                return error.response.data;
            } else {
                throw Error("Unknown Error");
            }
        }
    }

    public static async updateMe(
        attributes: Partial<User>,
        token: string
    ): Promise<User> {
        const uri = `/api/users/me`;

        const config = {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        };
        try {
            const response = await axios.patch(uri, attributes, config);
            return response.data;
        } catch (error: unknown) {
            if (request.isAxiosError(error) && error.response) {
                return error.response.data;
            } else {
                throw Error("Unknown Error");
            }
        }
    }

    public static async inviteUser(
        username: string,
        email: string,
        token: string
    ): Promise<{ status: number; data: string }> {
        const uri = `/api/users/flows/invite`;

        const config = {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        };
        try {
            const response = await axios.post(
                uri,
                { fullName: username, email },
                config
            );
            return { status: response.status, data: response.data };
        } catch (error: unknown) {
            if (request.isAxiosError(error) && error.response) {
                return {
                    status: error.response.status,
                    data: error.response.data,
                };
            } else {
                throw Error("Unknown Error");
            }
        }
    }

    public static async updateCompanyUserTags(
        tags: string[],
        userId: string,
        token: string
    ): Promise<{ status: number; data: string }> {
        const uri = `/api/users/flows/update-company-user-tags/${userId}`;

        const config = {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        };
        try {
            const response = await axios.patch(uri, tags, config);
            return { status: response.status, data: response.data };
        } catch (error: unknown) {
            if (request.isAxiosError(error) && error.response) {
                return {
                    status: error.response.status,
                    data: error.response.data,
                };
            } else {
                throw Error("Unknown Error");
            }
        }
    }
}
